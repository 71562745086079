import { useAuth0 } from "@auth0/auth0-react";
import { Container, Grid, Paper, LinearProgress, Typography, Button } from "@mui/material";
import FathomClient from "api/fathomapi";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Survey } from "./dataupload/types";
import EasyEdit, { Types } from "react-easy-edit";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import QuestionDataBuilder from "./dataupload/builder/formBuilder";
import TypeMapper from "./dataupload/builder/typeMapper";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function ReportCreate({ reportId, loadReports, parsedSurvery, setNewReportDrawerOpen, setParsedSurvey, setStep, analysing, step, sheetData, parseData, analysedSurvey }: any) {

  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const { programId } = useParams();
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);

  const updateQuestionType = (headerIndex: number, questionIndex: number, type: string) => {
    let survery = parsedSurvery.structure;
    survery[headerIndex].questions[questionIndex].type = type;
    setParsedSurvey({ ...parsedSurvery, structure: survery });
  }


  const saveReportData = async (surveyData: Survey) => {
    setCreating(true);
    const apiClient = new FathomClient(await getAccessTokenSilently());
    if (reportId !== undefined) {
      const { data } = await apiClient.put(
        `{clientId}/programs/` + programId + '/reports/' + reportId,
        surveyData
      );

      setCreating(false);
      setNewReportDrawerOpen(false);
      loadReports("", 1);
    } else {
      const data = await apiClient.post(
        `{clientId}/programs/` + programId + '/reports/',
        surveyData
      ).then(res => {
        return res.data
      }).catch((e: any) => {
        console.log(e);
      });
      if (data !== undefined) {
        setCreating(false);
        setNewReportDrawerOpen(false);
        loadReports("", 1);
      }
    }

  }

  useEffect(() => {
    if (reportId !== "" && reportId !== undefined) {
      setLoading(true);
      const fetchData = async () => {
        const apiClient = new FathomClient(await getAccessTokenSilently());
        const { data } = await apiClient.get(`{clientId}/programs/` + programId + `/reports/` + reportId);
        if (data.outcomes == null) {
          data.outcomes = [];
        }
        if (data.evaluationQuestions == null) {
          data.evaluationQuestions = [];
        }
        if (data.postAssessmentQuestions == null) {
          data.postAssessmentQuestions = [];
        }
        setLoading(false);
      };

      fetchData().catch(console.error);
    }

  }, [programId, getAccessTokenSilently, reportId]);


  const triggerAnalysis = () => {
    saveReportData(parsedSurvery)
  }

  return (
    <div>
        <Button disabled={step == 0} onClick={() => setStep(0)} sx={{ opacity: "0.6", textTransform: 'none', mt: -11.5, ml: 7 }}>
          <ArrowBackIcon sx={{ width: "16px", height: "16px" }} /> &nbsp; Back
        </Button>
      <Container>
        <Container>
          <br />
          <Grid container spacing={4} >
            <Grid item xs={12}>
              {" "}
              {!loading && (
                <>
                  <Grid container spacing={4} >
                    <Grid item xs={8}>
                      <Typography variant="h6" sx={{ mb: 2, ml: "20px" }}>
                        <EasyEdit
                          type={Types.TEXT}
                          onSave={(val: any) => {
                            setParsedSurvey({ ...parsedSurvery, title: val });
                          }}
                          saveButtonLabel={<DoneIcon />}
                          cancelButtonLabel={<CloseIcon />}
                          value={parsedSurvery.title}
                          placeholder="Enter a report title..."
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <DatePicker
                        sx={{ width: "100%" }}
                        value={dayjs(parsedSurvery.reportDate)}
                        label="Report date"
                        onChange={(startDate) => {
                          setParsedSurvey({ ...parsedSurvery, reportDate: dayjs(startDate) });
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
              {loading && (
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "20px",
                  }}
                >
                  <LinearProgress color="secondary" />
                  <br />
                  <br />
                  <br />
                </Paper>
              )}

              {analysing && (
                <Container>
                  <br />
                  <br />
                  <LinearProgress color="secondary" />
                  <br />
                  Analysing question structure..
                  <br />
                  <br />
                  <br />
                </Container>
              )}
              <br />
              {parsedSurvery.responseCount > -1 && !analysing && (
                <>
                  {step == 0 &&
                    <QuestionDataBuilder
                      responseCount={parsedSurvery.responseCount}
                      parseData={parseData}
                      parsedSurvery={parsedSurvery}
                      analysedSurvey={analysedSurvey}
                      allowSave={parsedSurvery.title !== ""}
                    />
                  }
                  {step == 1 &&
                    <TypeMapper
                      survey={parsedSurvery}
                      updateQuestionType={updateQuestionType}
                      triggerAnalysis={triggerAnalysis}
                      reportId={reportId}
                    />
                  }
                </>
              )}

            </Grid>
          </Grid>
        </Container>
      </Container>
    </div>
  );

}