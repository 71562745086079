import { Container } from "@mui/material";

import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import FathomClient from "@api/fathomapi";
import { useNavigate } from "react-router-dom";



export default function User() {
    const { getAccessTokenSilently, logout  } = useAuth0();
    const navigate = useNavigate();
    const [usera, setUser] = useState([]);

    useEffect(() => {
        // declare the data fetching function
        const fetchData = async () => {
            const api = new FathomClient(await getAccessTokenSilently())
            const { data } = await api.get(`auth/profile`);
            setUser(data);
            navigate("/programs");
        }
      
        // call the function
        fetchData()
          // make sure to catch any error
          .catch(() => {
            localStorage.setItem("returnTo", "window.location.origin");
            logout({ logoutParams: { returnTo: window.location.origin } })
        });
      }, [getAccessTokenSilently])

    return(
        <Container > 
            
        </Container>
    )

}
