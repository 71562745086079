import React, { useState, useCallback, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Typography, Modal, CircularProgress, List, Checkbox, Tooltip, Alert, TextField, Button, Grid, IconButton, DialogTitle } from "@mui/material";
import FathomClient from "api/fathomapi";
import { InsightChart, InsightsDataPoint, InsightsModalProps, Report, Question } from './insightTypes';
import Moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
export default function InsightsModal({ open, onClose, programId, onInsightCreated, insights, editingInsight }: InsightsModalProps) {
  const { getAccessTokenSilently } = useAuth0();
  const [reports, setReports] = useState<Report[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedQuestions, setSelectedQuestions] = useState<{ [reportId: string]: string }>({});
  const [selectedQuestionType, setSelectedQuestionType] = useState<string | null>(null);
  const [newInsightTitle, setNewInsightTitle] = useState("");
  const [description, setDescription] = useState("");
  const [chartType, setChartType] = useState("");

  const loadReportsWithQuestions = useCallback(async () => {
    setError(null);
    setLoading(true);
    try {
      const apiClient = new FathomClient(await getAccessTokenSilently());
      const response = await apiClient.get(`{clientId}/programs/${programId}/insights/questions`);
      const { reports } = response.data;

      if (!reports || reports.length === 0) {
        setError("No reports or questions found for this program.");
        setReports([]);
        return;
      }

      const reportsWithQuestions = reports.map((report: any) => ({
        id: report.reportId,
        title: report.title,
        date: report.date,
        questionAnalysis: {
          questionsSummary: report.questionIdsList.map((question: any) => ({
            questionId: question.questionId,
            reportId: report.reportId,
            questionHeader: question.title,
            type: question.type
          }))
        }
      }));

      setReports(reportsWithQuestions);
      if(editingInsight)
      {
        const structureResponse = await apiClient.get(`{clientId}/programs/${programId}/insights/structure`);
        const currentInsights = structureResponse.data.insightsDataPoints || [];
        const editedInsight = currentInsights.find((insight: InsightsDataPoint) => insight.order === editingInsight.order);

        if (editedInsight) {
          setNewInsightTitle(editedInsight.title);
          setDescription(editedInsight.description);
          setChartType(editedInsight.type);

          const selectedQuestionsMap: { [reportId: string]: string } = {};
          editedInsight.questionMappings.forEach((mapping: any) => {
            selectedQuestionsMap[mapping.reportId] = mapping.questionId;
          });
          setSelectedQuestions(selectedQuestionsMap);
        }
      }
    } catch (error) {
      console.error("Error fetching reports and questions:", error);
      setError("An error occurred while fetching reports and questions. Please try again later.");
      setReports([]);
    } finally {
      setLoading(false);
    }
  }, [getAccessTokenSilently, programId, open,editingInsight]);

  const handleQuestionSelect = useCallback((reportId: string, questionId: string, questionType: string) => {
    setSelectedQuestions(prev => {
      const newState = { ...prev };
      if (newState[reportId] === questionId) {
        delete newState[reportId];
        if (Object.keys(newState).length == 0) {
          setChartType("");
        }
      } else {
        if (Object.keys(newState).length == 0) {
          setChartType(questionType);
        }
        newState[reportId] = questionId;
      }
      return newState;
    });
  }, []);

  const canContinue = useCallback(() => {
    const selectedCount = Object.keys(selectedQuestions).length;
    const hasRatingQuestion = Object.values(selectedQuestions).some(questionId => {
      const question = reports.flatMap(r => r.questionAnalysis.questionsSummary).find(q => q.questionId === questionId);
      return question;
    });
    return selectedCount >= 2 && hasRatingQuestion;
  }, [selectedQuestions, reports]);

  const createOrUpdateInsight = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const apiClient = new FathomClient(await getAccessTokenSilently());

      const selectedQuestionDetails = Object.entries(selectedQuestions).map(([reportId, questionId]) => {
        const report = reports.find(r => r.id === reportId);
        const question = report?.questionAnalysis.questionsSummary.find((q: Question) => q.questionId === questionId);
        return { reportId, questionId, question, reportDate: report?.date };
      });

      // Fetch the current insights
      const currentInsightsResponse = await apiClient.get(`{clientId}/programs/${programId}/insights/structure`);
      let currentInsights = currentInsightsResponse.data.insightsDataPoints || [];

      const insightDataPoint: Omit<InsightsDataPoint, 'dataPointId'> = {
        title: newInsightTitle || "New Insight",
        description: description,
        order: editingInsight ? editingInsight.order : currentInsights.length,
        type: selectedQuestionDetails[0]?.question?.type ?? "rating",
        questionMappings: selectedQuestionDetails.map(({ reportId, questionId }) => ({
          reportId,
          questionId
        }))
      };

      if (editingInsight) {
        // Update existing insight
        currentInsights = currentInsights.map((insight: InsightsDataPoint) =>
          insight.order === editingInsight.order ? insightDataPoint : insight
        );
      } else {
        currentInsights.push(insightDataPoint);
      }

      const requestBody = {
        insightsDataPoints: currentInsights
      };

      const response = await apiClient.post(`{clientId}/programs/${programId}/insights`, requestBody);
      console.log("Create/Update insight response:", response);

      // Convert the insight to InsightChart type
      const updatedInsight: Omit<InsightChart, 'dataPointId'> = {
        title: insightDataPoint.title,
        description: insightDataPoint.description,
        order: insightDataPoint.order,
        type: insightDataPoint.type,
        data: {
          reportCategories: selectedQuestionDetails.map(q => q.reportDate || ''),
          dataSeries: {} // This will be populated by the backend
        },
        questionMappings: insightDataPoint.questionMappings
      };

      // Pass the updated insight and a flag indicating it's still processing
      onInsightCreated(updatedInsight, true);
      onClose();
    } catch (error) {
      console.error("Error creating/updating insight:", error);
      setError("An error occurred while creating/updating the insight. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, [selectedQuestions, newInsightTitle, description, getAccessTokenSilently, programId, onInsightCreated, onClose, reports, editingInsight]);
  useEffect(() => {
    if (open) {
      loadReportsWithQuestions();
    }
  }, [open, loadReportsWithQuestions]);

  const clearModal = () => {
    setNewInsightTitle("");
    setDescription("");
    setChartType("");
    setSelectedQuestions({});
    setReports([]);
    onClose();

  }

  return (
    <Modal open={open} onClose={clearModal}>
      <Box className="modal-content">

        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1} ><Typography variant="h6" component="h2">Create Insight</Typography></Box>
            <Box>
              <IconButton onClick={clearModal}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <Grid container>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Insight Title"
              value={newInsightTitle}
              onChange={(e) => setNewInsightTitle(e.target.value)}
              margin="normal"
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Insight description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              margin="normal"
              multiline
              rows={4}
            />
          </Grid>
        </Grid>


        <Typography sx={{ mt: 2, mb: 3 }}>Select questions from the reports below to compare</Typography>

        <Box className="modal-body">
          {loading ? (
            <CircularProgress />
          ) : error ? (
            <Alert severity="error">{error}</Alert>
          ) : reports.length === 0 ? (
            <Alert severity="info">No reports or questions available for this program.</Alert>
          ) : (
            <List>
              {reports.map((report: Report) => (
                <Box key={report.id} className="report-container">
                  <Typography variant="subtitle1">{report.title}</Typography>
                  <Typography variant="subtitle2">{Moment(report.reportDate).format("Do MMMM YYYY")}</Typography>
                  <Box className="scrollable-questions">
                    {report.questionAnalysis?.questionsSummary?.map((question: Question) => {
                      const isSelected = selectedQuestions[report.id] === question.questionId;
                      const isAllowed = allowedTypes.includes(question.type.toLowerCase());
                      const isDisabled = (chartType !== "" && question.type !== chartType && !isSelected);
                      const greyOut = (isDisabled || !isAllowed);
                      return (
                        <Tooltip
                          key={`${report.id}:${question.questionId}`}
                          title={getToolTipTitle(isAllowed, isDisabled, question.questionHeader)}
                          arrow
                          placement="top"
                        >
                          <Box
                            className={greyOut ? "question-box disabled" : "question-box"}

                            onClick={(e) => {
                              e.stopPropagation();
                              if (!isDisabled) {
                                handleQuestionSelect(report.id, question.questionId, question.type);
                              }
                            }}
                          >
                            <Box className="question-content">
                              <Typography variant="body2" className="question-text">
                                {question.questionHeader}
                              </Typography>
                              <Typography variant="subtitle2" sx={{ mt: 5 }}>
                                {question.type}
                              </Typography>
                            </Box>
                            <Checkbox
                              checked={isSelected}
                              disabled={greyOut}
                              onChange={(e) => {
                                e.stopPropagation();
                                if (!greyOut) {
                                  handleQuestionSelect(report.id, question.questionId, question.type);
                                }
                              }}
                              onClick={(e) => e.stopPropagation()}
                              className="question-checkbox"
                            />
                          </Box>
                        </Tooltip>
                      );
                    })}
                  </Box>
                </Box>
              ))}
            </List>
          )}
        </Box>
        <Box className="modal-footer" sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button
            variant="contained"
            onClick={createOrUpdateInsight}
            disabled={!canContinue() || loading || !!error}
          >
            {editingInsight ? "Update" : "Create"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}


let allowedTypes = [
  "rating",
  "nps",
  "opinion",
]

const getToolTipTitle = (isAllowed: boolean, isDisabled: boolean, title: string) => {

  if (!isAllowed) {
    return "This type can't be compared (yet)"
  }

  if (isDisabled) {
    return "You can only compare two questions of the same type"
  }

  return title;

}
