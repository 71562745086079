import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import { MappingAnalysisResponse, Question, Survey, Structure, Engagement, EngagementStructure } from '../types';
import { Box, Button } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

type QuestionDataBuilderProps = {
    responseCount: number
    parseData: (resultData: Engagement) => void,
    analysedSurvey: MappingAnalysisResponse,
    allowSave: boolean
    parsedEngagement: any,
    sheetData: any
};

const columns: GridColDef<(any)[number]>[] = [
    {
        field: 'title',
        headerName: 'Title',
        width: 600
    }
];

export default function QuestionDataBuilder({ responseCount, parseData, parsedEngagement, analysedSurvey, allowSave }: QuestionDataBuilderProps) {

    const [selectionModel, setSelectionModel] = React.useState(() =>
        analysedSurvey.questions.map((r) => r.id)
    );

    const [selectedHeaders, setSelectedHeaders] = React.useState(analysedSurvey.questions);

    const confirmData = () => {
        let headers = selectedHeaders.map((y) => y.id);
        let selectedQuestions =  analysedSurvey.questions.filter((z) => {
            return headers.includes(z.id);
        })

        parseData({
            structure: selectedQuestions.map((z) => {
                return {
                engagementHeader: z.title,
                responses: z.responses
            } as EngagementStructure })
        } as Engagement)
    }

    return (
        <>
            <b>Column mapping</b>
            <p>Select which columns you want uploaded.</p>
            <br />
            <div style={{ display: "flex", float: 'right', marginTop: '-75px' }}>
                <Button variant="outlined" disabled={!allowSave} onClick={() => confirmData()}>Confirm </Button>
            </div>

            <Box sx={{ height: 1000, width: '100%' }}>
                <DataGrid
                    rows={analysedSurvey.questions}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 50,
                            },
                        },
                    }}
                    pageSizeOptions={[5]}
                    checkboxSelection
                    rowSelectionModel={selectionModel}
                    onRowSelectionModelChange={(ids) => {
                        let selectedIDs = new Set(ids);
                        setSelectionModel(ids.map(y => y.toString()));

                        let selectedRowData = analysedSurvey.questions.filter((row) =>
                            selectedIDs.has(row.id.toString())
                        );
                        setSelectedHeaders(selectedRowData);
                    }}
                />
            </Box>
            <br /><br />
        </>
    );
};
