import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Grid } from '@mui/material';
import { mainListItems, secondaryListItems } from "./listItems";

const drawerWidth = 220;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function MiniDrawer({ loading, getClientName, user }: any) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    {!open && 
                             <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        sx={{
                            minHeight: 48,
                        }}
                    >
                        <span className="logo" >
                            <img src="img/fathom_icon.png" alt="logo" />
                        </span>
                    </IconButton>
                   }
                    {open && <>
                        <Grid container spacing={0}  
                         sx={{
                            marginLeft: "-12px",
                        }}>
                            <Grid item xs={3}>
                                <span className="logo">
                                    <img src="img/fathom_icon.png" alt="logo" />
                                </span>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="subtitle1" sx={{
                                    marginTop: "20px",
                                    marginLeft:"20px"
                                }}>{!loading && getClientName()}</Typography>
                            </Grid>
                        </Grid>
                        <IconButton onClick={handleDrawerClose}                          sx={{
                            marginLeft: "-12px",
                            marginTop: "23px"
                        }}>

                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </>
                    }
                </DrawerHeader>
                <br />
                <Divider />
                <br />

                <List>
                    {mainListItems(open)}
                </List>
                <br />
                <Divider />
                <List>
                    {secondaryListItems(open)}
                </List>
                <br />

                <Box
                    component="footer"
                    sx={{
                        py: 0,
                        mt: "0px",
                        backgroundColor: "#F3F3F4",
                        height: "0px",
                        paddingBottom: 9,
                        paddingRight: open ? "9px" : 0,
                        width: open ? "218px" : "63px",
                        bottom:0,
                        position: "fixed"
                    }}
                >
                    <Grid container spacing={0} className="userpicbox">
                        <Grid item xs={4}>
                            <span className="userpic">
                                <img src={user?.picture} />
                            </span>
                        </Grid>
                        <Grid item xs={8} sx={{ overflow: 'hidden', opacity: open ? 1 : 0 }}>
                            <b>{user?.name}</b>
                            <br />
                            <Typography variant="subtitle2">{user?.email}</Typography>
                        </Grid>

                    </Grid>
                </Box>
            </Drawer>
        </Box>
    );
}
