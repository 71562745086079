import { Paper, Typography, Container } from "@mui/material";

export default function OpenTextView({ currentQuestion }: any) {

    return(
        <>
        {currentQuestion.openTextResponses.length > 0 && (
            <>

              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "20px",
                }}
              >
                <Typography variant="h6">
                  {currentQuestion.questionHeader}
                </Typography>{" "}
                <hr />
                <Typography variant="subtitle2">
                  {currentQuestion.summary}
                </Typography>
                <br />
              </Paper></>
          )}
          {currentQuestion.openTextResponses.map((response: any) => {
            return (<>
              {response.headers.map((header: any) => {
                return (
                  <Paper
                    sx={{ marginBottom: "25px", borderShadow: "10px" }}
                    key={header.heading}
                  >
                    <Container>
                      <br />
                      <Typography variant="h6">{header.heading}</Typography>{" "}
                      <br />
                      <Typography variant="subtitle1">
                        {header.description}
                      </Typography>{" "}
                      <br />
                      {header.quote.map((quote: any) => {
                        return (
                          <>
                            {" "}
                            <Typography variant="subtitle1">
                              <i>&quot;{quote}&quot;</i>
                            </Typography>{" "}
                            <br />
                          </>
                        );
                      })}
                    </Container>
                  </Paper>
                )
              }
              )}</>)
          })}</>
    )

}