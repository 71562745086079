import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import Cookies from "universal-cookie";
export const CallbackPage = () => {
const env = process.env.REACT_APP_ENV;
const cookies = new Cookies();
const { user, error } = useAuth0();
if (error) {
    console.log(error)
    return (
        <div className="content-layout">
          <h1 id="page-title" className="content__title">
            Error
          </h1>
          <div className="content__body">
            <p id="page-description">
              <span>{error.message}</span>
            </p>
          </div>
        </div>
    );
  }

  return (
    <div className="page-layout">
    </div>
  );
};