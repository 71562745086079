import { Button } from "@mui/material";
import "./breadcrumb.css";

export default function BreadCrumb({ buttonFunc, buttonText, pageText }: any) {

    return (<>
        <br />
            <Button onClick={() => buttonFunc()} className="breadcrumbbutton">
                {buttonText}
            </Button> <span className="breadcrumbtext"><b>/ &nbsp; {pageText}</b></span>
        <br />
    </>
    )
}