import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";


export default function EngagementSeriesChart({ chart }: any) {

    let seriesData = [] as any[];
    Object.keys(chart.series as any).forEach( (key:string) => 
    {
        seriesData.push({
            name: key,
            data: chart.series[key].map((z:any)=> {
                return {
                    name: key,
                    y: z
                }
            })
        })
    });

    let options: Highcharts.Options = getPieChartOptions(chart, seriesData);

    return (
        <>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                sx={{mb:"-50px"}}
            />
        </>
    );
}

function getPieChartOptions(chart:any,seriesData:any ): Highcharts.Options {
    return {
        tooltip: {
            valueSuffix: ''
        },
        yAxis: {
            title: {
                text: 'Count'
            },
            enabled: true,
            labels: { enabled: true}
        },
        legend: {
            layout: 'vertical',
            align: 'left',
            verticalAlign: 'middle'
        },
        xAxis: {

            categories: chart.categories
        },
        title: {
            text: "",
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            series: {
                label: {
                    connectorAllowed: false
                },
            }
        },
        series: seriesData,
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom'
                    }
                }
            }]
        }
    };
}
