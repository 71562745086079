import { Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import { Question } from '../types';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

export type QuestionItemProps = {
  question: Question;
};

const QuestionItem = ({ question }: QuestionItemProps) => {
  return (
    <Card className='questionMapper'>
      <CardContent className='questionMapperContent'>
        <Grid container  >
          <Grid item xs={1} component={Stack} direction="column" justifyContent="center" >
            <DragIndicatorIcon />
          </Grid>
          <Grid item xs={11} component={Stack} direction="column" justifyContent="center" sx={{ml:"-10px"}} >
            {question.title}
          </Grid>
        </Grid>

      </CardContent>
    </Card>
  );
};

export default QuestionItem;
