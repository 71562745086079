import { Paper, Typography } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export default function RankingChart({ report, ranking, chartComponentRef }: any) {

  let options: Highcharts.Options = getRankingChartOptions(ranking);

    return (
        <>
          <Paper className="reportTile" key={ranking.title}>
            <h5>{ranking.title}</h5>
            <br />
            <br />
            <p>Hover over each bar to view the full rankings for that choice.</p>
            <br />

            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              ref={chartComponentRef}
            />
          </Paper>
        </>
      );
}


function getRankingChartOptions(ranking: any): Highcharts.Options {
    return {
        chart: {
            type: 'bar'
        },
        title: {
            text: "",
        },
        yAxis: {
            reversedStacks: false,
            title: {
                text: null
            },
            labels: { enabled: false },
            gridLineWidth: 0
        },
        xAxis: {
            categories: RankOneOrder(ranking.rankingData),
            reversedStacks: false,
            title: {
                text: null
            },
            gridLineWidth: 0

        },
        credits: {
            enabled: false
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            bar: {
                stacking: "normal",
                borderRadius: '25%',
                dataLabels: {
                    enabled: true
                },

            },
        },
        series: [{
            type: "bar",
            data: RankOneOrder(ranking.rankingData).map((key: string) => { return ranking.rankingData[key].rankOneCount }),
          }],
          tooltip: {
            formatter: function() {
              return ' ' +
              FullRankingOrder(ranking.rankingData[this.point.category].fullCalc).map((key: string) => {
                return '<br />' + ordinal_suffix_of(Number(key)) + " choice: " + ranking.rankingData[this.point.category].fullCalc[key] 
              }).join().replaceAll(",", "")
            }
          }    
    };
}

function RankOneOrder(rankData : any) {
    return Object.keys(rankData).filter((z) => z !== "").sort(function (a, b) {
        return rankData[b].rankOneCount - rankData[a].rankOneCount;
      });
}

function FullRankingOrder(fullCalc : any) {
    return Object.keys(fullCalc).filter((z) => z !== "").sort(function (a, b) {
        return Number([a]) - Number([b]);
      });
}

function ordinal_suffix_of(i:number) {
    let j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}