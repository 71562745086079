import { useAuth0 } from "@auth0/auth0-react";
import { Modal, Box, DialogTitle, Typography, IconButton, Grid, TextField, CircularProgress, Alert, List, Tooltip, Checkbox, Button, Chip, FormControl, Input, InputLabel, MenuItem, Select, FormLabel, RadioGroup, FormControlLabel } from "@mui/material";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { EngagementChartType, EngagementDataPoint, EngagementDataQueryResponse, EngagementQuestionHeaderOption, EngagementQuestionOptionsResponse } from "../../engagementTypes";
import FathomClient from "@api/fathomapi";
import EngagementBarChart from "../charts/engagementBarChart";
import PreviewBarChart from "../charts/engagementPieChart";
import Radio from '@mui/material/Radio';
import EngagementSeriesChart from "../charts/engagementSeriesChart";
import PieChartIcon from '@mui/icons-material/PieChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import SsidChartIcon from '@mui/icons-material/SsidChart';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function AddChart({ programId, engagementId, open, setModalOpen, order, fetchData }: any) {

    const [chartDataPoint, setChartDataPoint] = useState({
        title: "",
        order: order,
        type: EngagementChartType.Bar,
        filter: {
            questionHeaderIdFilter: [],
            responseFilter: []
        }
    } as EngagementDataPoint);
    const [optionsLoading, setOptionsLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);

    const [chartQueryData, setChartQueryData] = useState({
        categories: [],
        series: new Map<string, number[]>
    } as EngagementDataQueryResponse);

    const [chartOptions, setChartOptions] = useState({
        headerResponses: []
    } as EngagementQuestionOptionsResponse);

    const { getAccessTokenSilently } = useAuth0();
    const canSave = () => {
        return chartDataPoint.title !== "" && chartDataPoint.filter.questionHeaderIdFilter.length > 0;
    }
    const getOptions = async () => {
        setOptionsLoading(true);
        const apiClient = new FathomClient(await getAccessTokenSilently());
        const response = await apiClient.get(`{clientId}/programs/${programId}/engagement/${engagementId}/chart/options`);
        setChartOptions(response.data);
        setOptionsLoading(false);
    }

    const getData = async () => {
        setDataLoading(true);
        const apiClient = new FathomClient(await getAccessTokenSilently());

        var filter = {
            questionHeaderIdFilter: chartDataPoint.filter.questionHeaderIdFilter.map((y: any) => (y.questionHeaderId || y)),
            responseFilter: chartDataPoint.filter.responseFilter
        }

        const response = await apiClient.post(`{clientId}/programs/${programId}/engagement/${engagementId}/chart/data`, filter);
        setChartQueryData(response.data);
        setDataLoading(false);
    }

    useEffect(() => {
        getOptions();
    }, [order, open])

    useEffect(() => {
        if (chartDataPoint.filter.questionHeaderIdFilter.length > 0 && chartDataPoint.filter.questionHeaderIdFilter[0] != null) {
            getData();
        }
    }, [chartDataPoint.filter])

    const clearModal = () => {
        setModalOpen(false);
        setChartOptions({
            headerResponses: []
        })
        setChartQueryData({
            categories: [],
            series: new Map<string, number[]>
        })

        setChartDataPoint({
            title: "",
            order: order,
            type: EngagementChartType.Bar,
            filter: {
                questionHeaderIdFilter: [],
                responseFilter: []
            }
        })

    };

    const handleHeaderChange = (event: any) => {
        setChartDataPoint({
            ...chartDataPoint, filter: {
                ...chartDataPoint.filter,
                questionHeaderIdFilter: event.target.value
            }
        })

    };

    const handleResponseChange = (event: any) => {

        setChartDataPoint({
            ...chartDataPoint, filter: {
                ...chartDataPoint.filter,
                responseFilter: event.target.value
            }
        })
    };

    const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChartDataPoint({
            ...chartDataPoint, type: event.target.value as unknown as EngagementChartType
        })
    };

    const confirmData = async () => {
        setOptionsLoading(true);
        const apiClient = new FathomClient(await getAccessTokenSilently());

        var filter = {
            questionHeaderIdFilter: chartDataPoint.filter.questionHeaderIdFilter.map((y: any) => y.questionHeaderId),
            responseFilter: chartDataPoint.filter.responseFilter
        }

        chartDataPoint.filter = filter;

        await apiClient.post(`{clientId}/programs/${programId}/engagement/${engagementId}/chart`, chartDataPoint);
        setOptionsLoading(false);
        fetchData(engagementId);
        clearModal();
        
    }

    return (
        <Modal open={open} onClose={clearModal}>
            <Box className="modal-content">

                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1} ><Typography variant="h6" component="h2">Create Insight</Typography></Box>
                        <Box>
                            <IconButton onClick={clearModal}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <Grid container>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            label="Insight Title"
                            value={chartDataPoint.title}
                            onChange={(e) => setChartDataPoint({ ...chartDataPoint, title: e.target.value })}
                            margin="normal"
                        />
                    </Grid>

                </Grid>
                {chartDataPoint?.filter?.questionHeaderIdFilter.length == 0 &&
                    <Typography sx={{ mt: 2, mb: 3 }}>Select questions from below to generate a chart</Typography>}
                <Box className="modal-body">

                    {
                        chartQueryData?.categories.length > 0 && chartDataPoint?.filter?.questionHeaderIdFilter.length > 0 &&
                        <>
                            {
                                chartDataPoint.type == EngagementChartType.Bar &&
                                <EngagementBarChart chart={chartQueryData} />
                            }
                            {
                                chartDataPoint.type == EngagementChartType.Pie &&
                                <PreviewBarChart chart={chartQueryData} />
                            }
                            {
                                chartDataPoint.type == EngagementChartType.Series &&
                                <EngagementSeriesChart chart={chartQueryData} />
                            }
                            <hr />

                        </>
                    }

                    {!optionsLoading &&
                        <>

                            <Grid container>
                                <Grid item xs={4}>
                                    <FormControl>
                                        <FormLabel id="demo-radio-buttons-group-label">Chart type</FormLabel>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue={EngagementChartType.Bar}
                                            name="radio-buttons-group"
                                            onChange={handleTypeChange}
                                        >
                                            <FormControlLabel value={EngagementChartType.Bar} control={<Radio icon={<BarChartIcon />} sx={{
                                                '&.Mui-checked': {
                                                    color: '#6327E6',
                                                },
                                            }} checkedIcon={<BarChartIcon />} />} label="Bar" />
                                            <FormControlLabel value={EngagementChartType.Pie} control={<Radio icon={<PieChartIcon />} sx={{
                                                '&.Mui-checked': {
                                                    color: '#6327E6',
                                                },
                                            }} checkedIcon={<PieChartIcon />} />} label="Pie" />
                                            <FormControlLabel value={EngagementChartType.Series} control={<Radio icon={<SsidChartIcon />} sx={{
                                                '&.Mui-checked': {
                                                    color: '#6327E6',
                                                },
                                            }} checkedIcon={<SsidChartIcon />} />} label="Series" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl>
                                        <InputLabel id="question-filter">Questions</InputLabel>
                                        <Select
                                            labelId="question-filter"
                                            className="engagmentQuestionFilter"
                                            id="question-filter-chip"
                                            multiple
                                            value={chartDataPoint?.filter?.questionHeaderIdFilter}
                                            onChange={handleHeaderChange}
                                            input={<Input id="select-multiple-chip" />}
                                            sx={{ maxWidth: "400px" }}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((value: any) => (
                                                        <Chip key={value.questionHeaderId} label={value.questionHeader} />
                                                    ))}
                                                </Box>
                                            )}
                                            MenuProps={MenuProps}
                                        >
                                            {chartOptions.headerResponses.map((header) => (
                                                <MenuItem value={header as any} key={header.questionHeaderId}>
                                                    <Tooltip title={header.questionHeader.length > 45 ? header.questionHeader : ''}  >
                                                        <span>{header.questionHeader}</span>
                                                    </Tooltip>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl>
                                        <InputLabel id="response-filter">Responses</InputLabel>
                                        <Select
                                            labelId="response-filter"
                                            className="engagmentQuestionFilter"
                                            id="response-filter-chip"
                                            multiple
                                            value={chartDataPoint?.filter?.responseFilter}
                                            onChange={handleResponseChange}
                                            input={<Input id="select-multiple-chip" />}
                                            sx={{ maxWidth: "400px" }}

                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((value: any) => (
                                                        <Chip key={value} label={value} className="enagement-filter-chip" />
                                                    ))}
                                                </Box>
                                            )}
                                            MenuProps={MenuProps}
                                        >
                                            {
                                                chartOptions.headerResponses
                                                    .filter((y) => { return chartDataPoint?.filter?.questionHeaderIdFilter.map((z: any) => (z.questionHeaderId || z)).indexOf(y.questionHeaderId) > -1 })
                                                    .flatMap((a) => a.responses)
                                                    .filter((x, i, a) => a.indexOf(x) == i)
                                                    .map((response) => (
                                                        <MenuItem key={response} value={response}  >
                                                            <Tooltip title={response.length > 45 ? response : ''} >
                                                                <span>{response}</span>
                                                            </Tooltip>
                                                        </MenuItem>

                                                    ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </>
                    }

                </Box>
                <Box className="modal-footer" sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
                    <Grid item xs={3}>
                        <Tooltip title={canSave() ? '' : "Please set a title and select at least one question"}>
                            <span><Button variant="outlined" disabled={!canSave()} onClick={() => confirmData()}>Confirm </Button></span>
                        </Tooltip>
                    </Grid>

                </Box>
            </Box>
        </Modal>
    );
}