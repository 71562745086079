
import axios, { AxiosError, AxiosInstance,  AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { enqueueSnackbar } from "notistack";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const api = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
  });

setupInterceptorsTo(api);

api.defaults.withCredentials = true;

interface ApiErrorResponse {
  type: string;
  title: string;
  status: number;
  errors?: {
    [key: string]: string[];
  };
  traceId: string;
  ErrorMessage: string
}

function handleError(error: AxiosError) {
  let errorMessage = 'An unexpected error occurred. Please try again later.';

  if (error.response) {
    const errorData = error.response.data as ApiErrorResponse;

    if (errorData.errors) {
      // Collect all error messages
      const errorMessages = Object.values(errorData.errors).flat();

      // Join all error messages into a single string
      errorMessage = errorMessages.join('. ');
    } else if (errorData.title) {
      errorMessage = errorData.title;
    } else if (error.response.status === 401) {
      errorMessage = 'Authentication failed. Please log in again.';
    } else if (error.response.status === 403) {
      errorMessage = 'You do not have permission to perform this action.';
    } else if (error.response.status === 404) {
      errorMessage = 'The requested resource was not found.';
    } else if (error.response.status >= 500) {
      errorMessage = 'A server error occurred. Please try again later.';
    }

    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    errorMessage = 'No response received from the server. Please check your network connection.';
    console.log(error.request);
  } else {
    errorMessage = error.message || errorMessage;
    console.log('Error', error.message);
  }

  console.log(error.config);

  enqueueSnackbar(errorMessage, {
    variant: "error",
    autoHideDuration: 5000,
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right'
    }
  });
}

function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use((res) => {return res},handleError);
  axiosInstance.interceptors.response.use((res) => {return res},handleError);
  return axiosInstance;
}

export default class FathomClient {
  constructor(token: string){
    api.defaults.headers.common['Authorization'] = "Bearer " + token;
  }

  private replaceClientId(endpoint:string){
    if (endpoint.search("{clientId}") > -1 ) {
      var clientId = cookies.get("x-fathom-clientId");
      endpoint = endpoint.replace("{clientId}", clientId);
    }

    return endpoint;
  }

  get(endpoint: string)  {
    endpoint = this.replaceClientId(endpoint);
    return api.get(endpoint);
  }

  post(endpoint: string, data: any)  {
    endpoint = this.replaceClientId(endpoint);
    return api.post(endpoint, data);
  }

  put(endpoint: string, data: any)  {
    endpoint = this.replaceClientId(endpoint);
    return api.put(endpoint, data);
  }

  delete(endpoint: string,)  {
    endpoint = this.replaceClientId(endpoint);
    return api.delete(endpoint);
  }
}
