import "bootstrap/dist/css/bootstrap.css";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import theme from "./app/theme";
import App from "./app/App";
import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";
import { BrowserRouter } from "react-router-dom";
import { IntlProvider } from "react-intl";
require('dayjs/locale/en-gb')
require('dayjs/locale/en')

const container = document.getElementById("root")!;
const root = ReactDOM.createRoot(container);
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <IntlProvider locale={navigator.language}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={navigator.language}>
        <Auth0ProviderWithHistory>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </Auth0ProviderWithHistory>
      </LocalizationProvider>
      </IntlProvider>
    </BrowserRouter>
  </React.StrictMode>
);