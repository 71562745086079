import { read, utils } from "xlsx";
import { useState } from "react";
import { Engagement, MappingAnalysisResponse, Survey } from './types';
import FathomClient from 'api/fathomapi';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Container, Drawer, Grid, IconButton, Stack, styled, Typography } from '@mui/material';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import ReportCreate from "../create";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import dayjs from "dayjs";


export default function SpreadSheetParse({ loadEngagementReports }: any) {
    
    const { getAccessTokenSilently } = useAuth0();
    const [sheetData, setSheetData] = useState([] as any[]);
    const [analysedSurvey, setAnalysedSurvey] = useState({} as MappingAnalysisResponse);
    const [analysing, setAnalysing] = useState(false);
    const [newReportDrawerOpen, setNewReportDrawerOpen] = useState(false);
    const { programId } = useParams();
    const [parsedEngagement, setParsedEngagement] = useState({} as Engagement);
    const [creating, setCreating] = useState(false);

    const cleanString = (str: string) => {
        return str.replaceAll("â", "").replaceAll(/’/g, "").replaceAll(/'/g, "").replaceAll(/[^\x20-\x7E]/g, '').trim();
    }

    const getHeaderRow = (sheet: any, range: any) => {
        const headers = [];
        let C;
        const R = range.s.r;
        for (C = range.s.c; C <= range.e.c; ++C) {
            const cell = sheet[utils.encode_cell({ c: C, r: R })];
            let hdr = "EMPTY HEADER " + C;
            if (cell && cell.t == "s") {
                cell.v = cleanString(cell.v);
                if (cell.w) {
                    cell.w = cleanString(cell.w);
                }
            }
            if (cell && cell.t) {
                hdr = utils.format_cell(cell)
            }
            headers.push(cleanString(hdr));
        }
        return headers;
    }

    const parseData = (resultData: Engagement) => {

        resultData.name = parsedEngagement.name;
        resultData.reportDate = parsedEngagement.reportDate || dayjs();
        
        resultData.structure.forEach(questionBlock => {
            questionBlock.responses = [];
        });

        sheetData.forEach((i: any) => {
            resultData.structure.forEach(questionBlock => {

                let title = cleanString(questionBlock.engagementHeader);
                let val = i[title];
                if (val == undefined) {
                    let noSpaceTitle = title.replaceAll(" ", "");
                    let filtered = Object.keys(i).filter(z => {
                        return cleanString(z).replaceAll(" ", "") == noSpaceTitle;
                    });
                    if (filtered.length > 0) {
                        val = i[filtered[0]]
                    } else {
                        console.log(title);
                    }
                }
                questionBlock.responses.push(String(val))
            })
        });

        resultData.responseCount = sheetData.length;
        setParsedEngagement(resultData);
        saveReportData(resultData);
    }

    const saveReportData = async (engagementData: Engagement) => {
        setCreating(true);
        const apiClient = new FathomClient(await getAccessTokenSilently());
        const data = await apiClient.post(
            `{clientId}/programs/` + programId + '/engagement/',
            engagementData
          ).then(res => {
            return res.data
          }).catch((e: any) => {
            console.log(e);
          });
          if (data !== undefined) {
            setCreating(false);
            setNewReportDrawerOpen(false);
            loadEngagementReports("", 1);
          }
      }



    const runQuestionAnalysis = async (headers: string[], sheetData: any[]) => {
         let qha = [];
        for (let index = 0; index < headers.length; index++) {
            const hd = headers[index];
            qha.push({
                question: {
                    id: "",
                    header: hd
                },
                answers: sheetData.map(z => "" + z[hd])
            })
        }
        setAnalysing(true);

        const apiClient = new FathomClient(await getAccessTokenSilently());
        const response = await apiClient.post(
            `{clientId}/programs/` + programId + '/engagement/analyseQuestions', qha).catch((e) => {
                setAnalysing(false);
            });
        let data = response?.data;
        if (data != null) {
            setAnalysedSurvey(data);
            setAnalysing(false);
        } else {
            setAnalysing(false);
        } 
    }

    const manageFile = async (e: any) => {
        const data = await e.arrayBuffer();
        var workbook = read(data, {
            type: 'binary',
            cellDates: true,
            cellNF: false,
            cellText: false
          });
        var sheet = workbook.Sheets[Object.keys(workbook.Sheets)[0]];

        let firstCellVal = sheet["A1"];
        let rStart = 0, rEnd = 1;
        if ((firstCellVal?.h || firstCellVal?.v).indexOf("there are more sheets in this document") > -1) {
            rStart = 2, rEnd = 3;
        }
        var range = utils.decode_range(sheet['!ref'] as string);
        range.s.r = rStart;
        var header = getHeaderRow(sheet, range);
        range.s.r = rEnd;

        sheet['!ref'] = utils.encode_range(range);

        var aoa = utils.sheet_to_json(sheet, { header: header, range: range, defval: "" });

        let aoa1 = [] as any[];

        aoa.forEach((row: any) => {
            let newRow = {} as any;
            Object.keys(row as any).forEach(key => {
                let newKey = cleanString(key);
                if (!newKey.startsWith('EMPTY HEADER ')) {
                    newRow[newKey] = row[key];
                }
            })
            aoa1.push(newRow);
        })
        header = header.filter(z => !z.startsWith('EMPTY HEADER '));
        setSheetData(aoa1);
        setParsedEngagement({responseCount: aoa1.length, title: ""} as any as Engagement)
        runQuestionAnalysis(header, aoa);
    }

    const FileUploader = () => {
        const [fileName, setFileName] = useState('');

        const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {

            setNewReportDrawerOpen(true)
            const file = event.target.files?.[0];
            if (file) {
                setFileName(file.name);
                manageFile(file);
            }
        };

        const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault();
            setNewReportDrawerOpen(true)
            const file = event.dataTransfer.files[0];
            if (file) {
                setFileName(file.name);
                manageFile(file);
            }
        };

        const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault();
        };

        return (
            <>
                {!analysing && (
                    <div
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        onClick={() => document.getElementById('fileInput')?.click()}
                    >
                        <input
                            type="file"
                            id="fileInput"
                            accept=".csv,.xlsx,.xls,.ods"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                        <Grid container spacing={2} alignItems="center" sx={{ marginTop: "15px" }}>
                            <Grid sm={2} item>
                                <UploadFileOutlinedIcon sx={{ mt: "-20px" }} fontSize="large" />
                            </Grid>
                            <Grid sm={7} item sx={{ mt: "-18px" }}>
                                <b>Upload report</b>
                                <Typography sx={{ fontSize: "12px" }}>csv / xls / xlsx</Typography>
                            </Grid>
                            <Grid sm={2} item sx={{ mt: "-18px" }}>
                                <Button sx={{
                                    flexShrink: 0,
                                    '& .MuiButton-startIcon': {
                                        mr: 0,
                                        ml: 0
                                    }
                                }}

                                    disableElevation
                                    startIcon={<ArrowForwardOutlinedIcon fontSize="large" />}>
                                </Button>

                            </Grid>
                        </Grid>
                    </div>
                )}
            </>
        );
    };

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
        marginTop: "20px"
    }));

    const resetCreate = () => {
        setNewReportDrawerOpen(false);
    }

    return (
        <div>
            <FileUploader />
            <Drawer
                anchor='right'
                sx={{
                    width: "600px",
                }}
                open={newReportDrawerOpen}
                PaperProps={{
                    sx: { width: "800px" },
                }}
            >
                <DrawerHeader >
                    <Container>
                        <Stack spacing={2} direction="row" justifyContent="right" sx={{ mt: '0px' }}>
                            <IconButton aria-label="open">
                                <CloseOutlinedIcon onClick={() => resetCreate()} />
                            </IconButton>
                        </Stack>
                    </Container>
                </DrawerHeader>
                <ReportCreate
                    loadReports={loadEngagementReports}
                    analysing={analysing}
                    parsedEngagement={parsedEngagement}
                    setParsedEngagement={setParsedEngagement}
                    sheetData={sheetData}
                    analysedSurvey={analysedSurvey}
                    parseData={parseData}
                    setNewReportDrawerOpen={setNewReportDrawerOpen} />
            </Drawer>
        </div>
    );
}