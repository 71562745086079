import { useAuth0 } from "@auth0/auth0-react";
import { Container, Grid, Paper, LinearProgress, Typography, Button, TextField } from "@mui/material";
import FathomClient from "api/fathomapi";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import QuestionDataBuilder from "./dataupload/builder/formBuilder";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function ReportCreate({ reportId, loadReports, parsedEngagement, setNewReportDrawerOpen, setParsedEngagement, setStep, analysing, step, sheetData, parseData, analysedSurvey }: any) {

  const { getAccessTokenSilently } = useAuth0();
  const { programId } = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (reportId !== "" && reportId !== undefined) {
      setLoading(true);
      const fetchData = async () => {
        const apiClient = new FathomClient(await getAccessTokenSilently());
        const { data } = await apiClient.get(`{clientId}/programs/` + programId + `/reports/` + reportId);
        if (data.outcomes == null) {
          data.outcomes = [];
        }
        if (data.evaluationQuestions == null) {
          data.evaluationQuestions = [];
        }
        if (data.postAssessmentQuestions == null) {
          data.postAssessmentQuestions = [];
        }
        setLoading(false);
      };

      fetchData().catch(console.error);
    }

  }, [programId, getAccessTokenSilently, reportId]);

  return (
    <div>
      <Container>
        <Container>
          <br />
          <Grid container spacing={4} >
            <Grid item xs={12}>
              {" "}
              {!loading && (
                <>
                  <Grid container spacing={4} >
                    <Grid item xs={8}>
                      <Typography variant="h6" sx={{ mb: 2, mt: "-15px" }}>
                        <TextField
                            fullWidth
                            label="Engagement report title"
                            value={parsedEngagement.name}
                            onChange={(e: any) => setParsedEngagement({ ...parsedEngagement, name: e.target.value })}
                            margin="normal"
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <DatePicker
                        sx={{ width: "100%" }}
                        value={dayjs(parsedEngagement.reportDate)}
                        label="Report date"
                        onChange={(startDate) => {
                          setParsedEngagement({ ...parsedEngagement, reportDate: dayjs(startDate) });
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
              {loading && (
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "20px",
                  }}
                >
                  <LinearProgress color="secondary" />
                  <br />
                  <br />
                  <br />
                </Paper>
              )}

              {analysing && (
                <Container>
                  <br />
                  <br />
                  <LinearProgress color="secondary" />
                  <br />
                  Analysing question structure..
                  <br />
                  <br />
                  <br />
                </Container>
              )}
              <br />
              {parsedEngagement.responseCount > -1 && !analysing && (
                  <QuestionDataBuilder
                    responseCount={parsedEngagement.responseCount}
                    parseData={parseData}
                    parsedEngagement={parsedEngagement}
                    analysedSurvey={analysedSurvey}
                    allowSave={parsedEngagement.name !== "" && parsedEngagement.name !== undefined}
                    sheetData={sheetData}
                  />
              )}

            </Grid>
          </Grid>
        </Container>
      </Container>
    </div>
  );

}