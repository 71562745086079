import { Paper, Typography } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export default function MultiChart({ report, multi, chartComponentRef }: any) {

  let rate = 100;
  let unres = 0;
  if (multi.multiCount.hasOwnProperty("")) {
    unres = multi.multiCount[""];
    let res = report.participants;

    rate = Math.round(100 - (unres / res) * 100);
  }

  let options: Highcharts.Options = getMultiChartOptions(multi);

  return (
    <>
      <Paper className="reportTile" key={multi.title}>
        <Typography sx={{ opacity: "0.8", textTransform: "none" }}>
          {rate}% response rate{" "}
          {unres > 0 && (
            <span>({unres} participants didn&apos;t respond)</span>
          )}
        </Typography>
        <br />
        <br />
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartComponentRef}
        />
      </Paper>
    </>
  );
}

function getMultiChartOptions(multi: any): Highcharts.Options {
  return {
    title: {
      text: multi.title,
    },
    yAxis: {
      reversedStacks: false,
      title: {
        text: null
      },
      labels: { enabled: false }

    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: [""],
      reversedStacks: false,
    },
    plotOptions: {
      series: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
        },
      },
    },
    series: GetOrderedKeys(multi.multiCount).map((key: string) => {
      return {
        name: key,
        type: "bar",
        data: [multi.multiCount[key]],
      };
    }),
  };
}

function GetOrderedKeys(multi: Record<string, number>) {
  return Object.keys(multi)
    .filter((z) => z !== "")
    .sort(function (a, b) {
      return parseInt(a) - parseInt(b);
    });
}