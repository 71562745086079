import { Box, Button, Container, Divider, FormControl, Grid, IconButton, InputLabel, ListItemIcon, Menu, MenuItem, MenuList, Paper, Select, Stack, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import CreateIcon from '@mui/icons-material/Create';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Highcharts from 'highcharts';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import React from "react";
import EasyEdit, { Types } from "react-easy-edit";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import PieChartIcon from '@mui/icons-material/PieChart';
import EngagementBarChart from "./charts/engagementBarChart";
import EngagementPieChart from "./charts/engagementPieChart";

import FathomClient from "@api/fathomapi";
import { useAuth0 } from "@auth0/auth0-react";
import ConfirmDialog from "@einhorn/confirmDialog";
import "./engagement.css"
import AddChart from "./addChart/addChart";
import EngagementSeriesChart from "./charts/engagementSeriesChart";

export default function ViewEngagementReportCharts({ report, fetchData }: any) {

    const { getAccessTokenSilently } = useAuth0();
    const [editMode, setEditMode] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [triggerApi, setTriggerApi] = useState(false);
    const [chartDataPoint, setChartDataPoint] = useState(undefined as any);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [loadedReport, setLoadedReport] = useState(report);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

    const open = Boolean(anchorEl);
    const handleEditClose = () => {
        setAnchorEl(null);
    };

    const firstUpdate = useRef(true);

    const handleEditClick = (event: React.MouseEvent<HTMLButtonElement>, chart: any) => {
        setChartDataPoint(chart)
        setAnchorEl(event.currentTarget);
    };

    const updateChart = async () => {
        if (chartDataPoint !== undefined && triggerApi) {
            let indexToUpdate = report.charts.findIndex((item: any) => item.dataPointId === chartDataPoint.dataPointId);
            report.charts[indexToUpdate] = chartDataPoint;
            setLoadedReport(report);
            const apiClient = new FathomClient(await getAccessTokenSilently());
            await apiClient.put(`{clientId}/programs/` + loadedReport.programId + `/engagement/` + loadedReport.engagementId + `/chart/` + chartDataPoint.dataPointId, chartDataPoint);
            setTriggerApi(false)
        }
    }

    const deleteChart = async () => {
        if (chartDataPoint !== undefined) {
            let indexToUpdate = report.charts.findIndex((item: any) => item.dataPointId === chartDataPoint.dataPointId);
            report.charts.splice(indexToUpdate, 1)
            setLoadedReport(report);
            const apiClient = new FathomClient(await getAccessTokenSilently());
            await apiClient.delete(`{clientId}/programs/` + loadedReport.programId + `/engagement/` + loadedReport.engagementId + `/chart/` + chartDataPoint.dataPointId);
            setConfirmDeleteOpen(false);
            handleEditClose()
        }
    }

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        updateChart()
    }, [chartDataPoint])

    return (
        <>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleEditClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                disableScrollLock={true}

            >
                {chartDataPoint !== undefined &&
                    <>
                        <MenuItem sx={{ minWidth: "277px" }}>
                            <Typography variant="body2">Title &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Typography>
                            <Typography variant="body2" style={{ marginLeft: 'auto' }}>
                                <EasyEdit
                                    type={Types.TEXT}
                                    onSave={(val: any) => {
                                        setTriggerApi(true)
                                        setChartDataPoint({ ...chartDataPoint, title: val })

                                    }}
                                    saveButtonLabel={<DoneIcon fontSize='small' />}
                                    cancelButtonLabel={<CloseIcon fontSize='small' />}
                                    value={chartDataPoint.title}
                                    placeholder="Title"
                                />
                            </Typography>
                        </MenuItem>
                        <MenuItem sx={{ minWidth: "277px" }}>
                            <PieChartIcon fontSize="small" style={{ marginRight: '8px' }} />
                            <Typography variant="body2">Chart type</Typography>
                            <Select
                                style={{ marginLeft: 'auto' }}
                                value={chartDataPoint.type}
                                disableUnderline={true}
                                variant="standard"
                                onChange={(val: any) => {
                                    setTriggerApi(true)
                                    setChartDataPoint({ ...chartDataPoint, type: val.target.value });
                                }}
                            >
                                <MenuItem value={"Pie"}>Pie chart</MenuItem>
                                <MenuItem value={"Bar"}>Bar chart</MenuItem>
                                <MenuItem value={"Series"}>Series chart</MenuItem>
                            </Select>
                        </MenuItem>
                        <MenuItem style={{ color: 'red' }} onClick={() => {
                            setConfirmDeleteOpen(true)
                        }}>
                            <DeleteIcon fontSize="small" style={{ marginRight: '8px' }} />
                            <Typography variant="body2">Delete metric</Typography>
                        </MenuItem>
                    </>
                }
            </Menu>
            <ConfirmDialog
                title="Delete chart"
                content="Are you sure you want to delete this chart?"
                open={confirmDeleteOpen}
                setOpen={setConfirmDeleteOpen}
                confirmAction={deleteChart}
            />
            <Box maxWidth="xl" mx="auto">
                <Grid container>
                    <Grid xs={2}>
                        <Typography variant="h6" sx={{ my: 4, ml: 2 }}>Engagement &nbsp;
                            {!editMode &&
                                <IconButton onClick={() => setEditMode(true)} sx={{ opacity: "0.6", textTransform: 'none', mt: 0 }}>
                                    <CreateIcon fontSize="small" />
                                </IconButton >
                            }{editMode &&
                                <IconButton onClick={() => setEditMode(false)} sx={{ opacity: "0.6", textTransform: 'none', mt: 0 }}>
                                    <VisibilityIcon fontSize="small" />
                                </IconButton >
                            } </Typography>
                    </Grid>


                </Grid>
                <Grid container spacing={2}>
                    {loadedReport.charts.map((chart: any) => {
                        return <Grid item xs={6} key={chart.dataPointId}>
                            <Paper
                                className="engagement-box"
                                key={chart.questionHeader}
                            >
                                <Stack spacing={2} direction="row" justifyContent="right" sx={{ mb: -4 }} hidden={!editMode} >
                                    <IconButton aria-label="open" sx={{}} onClick={(ev: any) => handleEditClick(ev, chart)}>
                                        <MoreHorizIcon fontSize="small" />
                                    </IconButton>
                                </Stack>

                                <Typography variant="h6"> {chart.title}</Typography>
                                {chart.type.toLowerCase() == "pie" &&
                                    <EngagementPieChart chart={chart.data} highcharts={Highcharts} />
                                }
                                {chart.type.toLowerCase() == "bar" &&
                                    <EngagementBarChart chart={chart.data} highcharts={Highcharts} />
                                }
                                {chart.type.toLowerCase() == "series" &&
                                    <EngagementSeriesChart chart={chart.data} highcharts={Highcharts} />
                                }
                            </Paper>
                        </Grid>
                    })}
                    <Grid item xs={6}>
                        <Paper className="engagement-box add-engagement-box" hidden={!editMode}>
                            <Button variant="outlined" onClick={() => setModalOpen(true)}>
                                Add Insight
                            </Button>
                        </Paper>
                    </Grid>

                    <AddChart
                        fetchData={fetchData}
                        setModalOpen={setModalOpen}
                        programId={loadedReport.programId}
                        engagementId={loadedReport.engagementId}
                        open={modalOpen}
                        order={loadedReport.charts.length}
                    />
                </Grid>
            </Box>
        </>
    )
}