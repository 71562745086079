import HighchartsReact from "highcharts-react-official";
import { InsightChart } from "../insightTypes";
import Highcharts from "highcharts";
export default function NpsInsightsChart({ insight }: any) {
    
    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={getNpsBarOptions(insight)} />

    )
}


function getNpsBarOptions(insight: any): Highcharts.Options {

  const { data, title, description } = insight;

  return {
      chart: {
          type: 'bubble',
          height: '50%',
          alignTicks: false
      },
      title: {
          text: ''
      },
      xAxis: {
          categories: data.reportCategories,
      },
      yAxis: [
        {
          title: {
             text: "Net Promoter Score"
          },
          min: -100,
          max: 100,
          tickInterval: 100,
          labels: {
            enabled: false
        },
          opposite: true
       },{
          min: 0,
          max: 100,
          title: {
              text: ''
          },
          lineWidth: 0,
          minorGridLineWidth: 0,
          gridLineColor: "transparent",
          lineColor: "transparent",
          labels: {
              enabled: false
          },
          minorTickLength: 0,
          tickLength: 20,

      }],
      legend: {
          reversed: true,
          itemMarginTop: -20
      },
      plotOptions: {
          series: {
              stacking: 'normal',
              dataLabels: {
                  enabled: true,
                  format: '{point.y:,.0f} %'
              }
          },
          bubble: {
            minSize: 10,
            maxSize: 30,
            dataLabels: {
               enabled: true,
               format: "{point.y}"
            }
         }
      },
      credits: {
          enabled: false
      },
      series: [{
          name: 'Promoters',
          data: data.dataSeries["Promoters"],
          color: '#55BF3B',
          stack: 0,
          type: "bar",
          yAxis: 1

      }, {
          name: 'Passives',
          data: data.dataSeries["Neutral"],
          color: '#DADADA',
          stack: 0,
          type: "bar",
          yAxis: 1

      },
      {
          name: 'Detractors',
          data: data.dataSeries["Detractors"],
          color: '#DF5353',
          stack: 0,
          type: "bar",
          yAxis: 1
      },
      {
          name: 'Score',
          data: data.dataSeries["Score"],
          type: "bar",
          yAxis: 0,
          stack: 1,
          dataLabels: {
            enabled: true,
            format: "{point.y}"
         }

      }
    ]
  }
}