import { Grid, InputLabel, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import EasyEdit, { Types } from "react-easy-edit";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

export default function ProgramSetup({ value, setValue }: any) {

  return (
    <div>
      <Grid container spacing={4} >
        <Grid item xs={8}>
          <Typography variant="h6" sx={{ mb: 2, ml: "0px" }}>
            <EasyEdit
              type={Types.TEXT}
              onSave={(val: any) => {
                setValue({ ...value, name: val });
              }}
              saveButtonLabel={<DoneIcon />}
              cancelButtonLabel={<CloseIcon />}
              value={value.name}
              placeholder="Enter a title..."
            />
          </Typography>
        </Grid>
        <Grid sm={4} item>
          <DatePicker
            sx={{ width: "100%" }}
            value={dayjs(value.startDateUtc)}
            label="Start date"
            onChange={(startDate) => {
              setValue({ ...value, startDateUtc: startDate?.toISOString() });
            }}
          />
        </Grid>
      </Grid>
      <Typography variant="subtitle1" gutterBottom>
        Description
      </Typography>
      <TextField id="outlined-basic" fullWidth variant="outlined"
        value={value.description}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setValue({ ...value, description: event.target.value });
        }}
        label="Write here.."
        multiline
        rows={4}
      /> 
      <br />
      <br />
    </div>
  );
}