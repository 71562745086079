import Title from "@einhorn/title";
import { Container, Grid, Paper } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
require('highcharts/highcharts-more')(Highcharts);

export default function NpsChart({ nps, chartComponentRef }: any) {

    let gaugeOptions: Highcharts.Options = getNpsGaugeOptions(nps);
    let total = nps.nps.promoters + nps.nps.neutral + nps.nps.detractors;
    let barOptions: Highcharts.Options = getNpsBarOptions(nps, total);

    return (
        <>
            <Paper className="reportTile" key={nps.title}>
                <h5>{nps.title}</h5>
                <Grid container>
                    <Grid item xs={6}>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={gaugeOptions}
                            ref={chartComponentRef}
                        /></Grid>
                    <Grid item xs={6}>
                        <br />
                        <Grid container>
                            <Grid item xs={7}><h4>NPS Score</h4></Grid>
                            <Grid item xs={5}><h4>{nps.nps.score}</h4></Grid>
                            <br />
                            <br />
                            <Grid item xs={7}>Promoters &nbsp;&nbsp;(9-10)</Grid>
                            <Grid item xs={5}>{nps.nps.promoters} &nbsp; ({Math.round(nps.nps.promoters / total * 100)}%)</Grid>

                            <Grid item xs={7}>Passives&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (7-8)</Grid>
                            <Grid item xs={5}>{nps.nps.neutral} &nbsp; ({Math.round(nps.nps.neutral / total * 100)}%)</Grid>

                            <Grid item xs={7}>Detractors &nbsp;&nbsp;(0-6)</Grid>
                            <Grid item xs={5}>{nps.nps.detractors} &nbsp; ({Math.round(nps.nps.detractors / total * 100)}%)</Grid>
                            <br />
                            <br />
                            <Grid item xs={7}>Total</Grid>
                            <Grid item xs={5}>{total}</Grid>
                        </Grid>

                    </Grid>
                </Grid>
                <Container sx={{mt:"-30px"}}>
                    <Container>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={barOptions}
                            ref={chartComponentRef}
                        />
                    </Container>
                </Container>
            </Paper>
        </>
    );

}
function getNpsBarOptions(nps: any, total: number): Highcharts.Options {
    return {
        chart: {
            type: 'bar',
            height: '17%',
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: [''],
            visible: false,
        },
        yAxis: {
            min: 0,
            max: 100,
            title: {
                text: ''
            },
            lineWidth: 0,
            minorGridLineWidth: 0,
            gridLineColor: "transparent",
            lineColor: "transparent",
            labels: {
                enabled: false
            },
            minorTickLength: 0,
            tickLength: 0,

        },
        legend: {
            reversed: true,
            itemMarginTop: -20
        },
        plotOptions: {
            series: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true,
                    format: '{point.y:,.0f} %'
                }
            }
        },
        credits: {
            enabled: false
        },
        series: [{
            name: 'Promoters ',
            data: [Math.round(nps.nps.promoters / total * 100)],
            color: '#55BF3B',
            type: "bar",
        }, {
            name: 'Passives',
            data: [Math.round(nps.nps.neutral / total * 100)],
            color: '#DADADA',
            type: "bar",
        },
        {
            name: 'Detractors',
            data: [Math.round(nps.nps.detractors / total * 100)],
            color: '#DF5353',
            type: "bar",
        }]
    }
}

function getNpsGaugeOptions(nps: any): Highcharts.Options {
    return {
        chart: {
            spacingTop: 0,
            spacingRight: 0,
            spacingBottom: 0,
            spacingLeft: 0,
            type: 'solidgauge',
            plotBorderWidth: 0,
            plotShadow: false,
            plotBackgroundColor: 'white',
            plotBackgroundImage: '',
            height: '100%',
            backgroundColor: 'white',
            margin: [0,35,70,35]
        },

        title: {
            text: "",
            style: {
                fontSize: "12px"
            }
        },

        pane: {
            center: ['50%', '85%'],
            size: '100%',
            startAngle: -100,
            endAngle: 100,
            background: [{
                backgroundColor: 'white',
                innerRadius: '100%',
                outerRadius: '100%',
                shape: 'arc'
            }]
        },
        credits: {
            enabled: false
        },
        // the value axis
        yAxis: {
            min: -100,
            max: 100,
            tickPixelInterval: 20,
            tickPosition: 'outside',
            tickColor: '#FFFFFF',
            tickInterval: 50,
            minorTickLength: 0,
            tickLength: 0,
            tickWidth: 20,
            labels: {
                distance: 20,
                style: {
                    fontSize: '16px'
                }
            },
            lineWidth: 0,
            plotBands: [{
                from: -100,
                to: 0,
                color: '#DF5353',
                thickness: 25,
            }, {
                from: 0,
                to: 100,
                color: '#55BF3B',
                thickness: 25,
            }]
        },
        series: [{
            name: 'Score',
            data: [nps.nps.score],
            type: "gauge",
            tooltip: {
                valueSuffix: ''
            },
            dataLabels: {
                format: '{y}',
                borderWidth: 0,
                color: (
                    Highcharts.defaultOptions.title &&
                    Highcharts.defaultOptions.title.style &&
                    Highcharts.defaultOptions.title.style.color
                ) || '#333333',
                style: {
                    fontSize: '16px'
                }
            },
            dial: {
                radius: '80%',
                backgroundColor: 'gray',
                baseWidth: 12,
                baseLength: '0%',
                rearLength: '0%',
                
            },
            pivot: {
                backgroundColor: 'gray',
                radius: 6,
                
            }
        }]

    };
}