import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import {
    useSensors,
    useSensor,
    PointerSensor,
    KeyboardSensor,
    DndContext,
    closestCorners,
    DragEndEvent,
    DragStartEvent,
    DragOverlay,
    DropAnimation,
    defaultDropAnimation,
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import QuestionHeader from './questionHeader';
import QuestionItem from './questionItem';
import { MappingAnalysisResponse, Question, Survey, Structure } from '../types';
import { Button } from '@mui/material';

type QuestionDataBuilderProps = {
    responseCount: number
    parseData: (resultData: Survey) => void,
    analysedSurvey: MappingAnalysisResponse,
    allowSave: boolean
    parsedSurvery: any
};

export default function QuestionDataBuilder({ responseCount, parseData, parsedSurvery, analysedSurvey, allowSave }: QuestionDataBuilderProps) {
    const initialiseSurvey = (analysedSurvey: any) => {
        if(analysedSurvey.title !== undefined) {
            analysedSurvey.unmappedQuestions = [];
            return analysedSurvey as Survey;
        }

        let str = analysedSurvey.questions.map((q : Question) => ({
            questionHeader: q.title,
            questions: [{
                title: q.title,
                type: q.type,
                id: q.id,
                responses: []
            }]
        }));

        let survery = {
            structure: str,
            responseCount: responseCount,
            title: parsedSurvery.title,
            reportDate: parsedSurvery.reportDate     
        } as Survey;
        return survery;
    }

    const [initialsurveyStructure, setInitialsurveyStructure] = useState(initialiseSurvey(analysedSurvey));

    const getInitialMap = (initialsurveyStructure: Survey, unmappedQuestions: Question[]): Map<string, string> => {
        let map = new Map(unmappedQuestions.map(q => [q.id, "Unmapped"]));
        for (let x = 0; x < initialsurveyStructure.structure.length; x++) {
            const header = initialsurveyStructure.structure[x];
            for (let y = 0; y < header.questions.length; y++) {
                const question = header.questions[y];
                map.set(question.id, header.questionHeader)
            }
        }

        map.set("Unmapped", "Unmapped")

        return map;
    }
    useEffect(() => {
        setInitialsurveyStructure(initialiseSurvey(analysedSurvey));
        setFormState({
            unmappedQuestions: analysedSurvey.unmappedQuestions,
            posMap: getInitialMap(initialsurveyStructure, analysedSurvey?.unmappedQuestions ?? []),
            surveyStructure: initialsurveyStructure
        })
    }, [analysedSurvey]);


    const [formState, setFormState] = useState({
        unmappedQuestions: [] as Question[],
        surveyStructure: initialsurveyStructure,
        posMap: new Map() as Map<string, string>
    });

    const updateMap = (map: Map<string, string>, k: string, v: string) => {
        return new Map(map.set(k, v));
    }

    const [activeQuestion, setActiveQuestion] = useState<null | Question>(null);


    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragStart = ({ active }: DragStartEvent) => {
        let activeHeader = formState.posMap.get(active.id.toString());
        let activeQuestionIndex = formState.surveyStructure.structure.findIndex(z => z.questionHeader == activeHeader);
        if (activeQuestionIndex == -1) {
            activeQuestionIndex = formState.unmappedQuestions.findIndex(z => z.id == active.id.toString());
            setActiveQuestion(formState.unmappedQuestions[activeQuestionIndex]);
        } else {
            let headerQs = formState.surveyStructure.structure[activeQuestionIndex].questions;
            let activeQuestionQuestionIndex = headerQs.findIndex(z => z.id == active.id);
            setActiveQuestion(headerQs[activeQuestionQuestionIndex]);
        }

    };

    const handleDragEnd = ({ active, over }: DragEndEvent) => {
        let overId = over?.id;
        let activeId = active?.id;

        if (overId == null || overId == activeId) {
            return;
        }
        let unmappedQs = formState.unmappedQuestions;
        let surv = formState.surveyStructure;
        let posMap = formState.posMap;

        let activeHeader = formState.posMap.get(activeId.toString());

        if (activeHeader == overId) {
            return;
        }

        if (activeHeader == "Unmapped") {
            let activeQuestionIndexUnMapped = unmappedQs.findIndex(z => z.id == activeId);
            if (activeQuestionIndexUnMapped > -1) {
                unmappedQs = unmappedQs.filter((_, i) => i !== activeQuestionIndexUnMapped);
                if (unmappedQs.length == 0) {
                    unmappedQs.push({
                        id: "unmapped"
                    } as Question)
                    posMap = updateMap(posMap, "unmapped", "Unmapped");
                }
            }
        } else {
            let activeQuestionIndex = surv.structure.findIndex(z => z.questionHeader == activeHeader);
            if (activeQuestionIndex == -1 || activeQuestionIndex >= surv.structure.length) {
                console.log(activeHeader)
            }
            let activeQuestionQuestionIndex = surv.structure[activeQuestionIndex].questions.findIndex(z => z.id == activeId);
            if (activeQuestionQuestionIndex == -1) {
                console.log(activeHeader)
            }
            surv.structure[activeQuestionIndex].questions.splice(activeQuestionQuestionIndex, 1);
            if (surv.structure[activeQuestionIndex].questions.length == 0) {
                surv.structure.splice(activeQuestionIndex, 1);
            }
        }

        let overHeader = posMap.get(overId.toString());
        if (overHeader == "Unmapped") {
            if (unmappedQs.length == 1 && unmappedQs[0].id == "unmapped") {
                unmappedQs = [];
                posMap = updateMap(posMap, "unmapped", "Unmapped");
            }
            unmappedQs.push(activeQuestion as Question);
            posMap = updateMap(posMap, activeId.toString(), "Unmapped");

        } else {
            let overQuestionIndex = -1;
            if (overId == "New question") {
                surv.structure.push({
                    questionHeader: activeQuestion?.title || "",
                    questions: [{
                        title: activeQuestion?.title,
                        type: activeQuestion?.type,
                        responses: [],
                        id: activeId
                    } as Question]
                });
                overQuestionIndex = surv.structure.length - 1;
            } else {
                overQuestionIndex = surv.structure.findIndex(z => z.questionHeader == (overHeader || overId));
                if (overQuestionIndex == -1 || overQuestionIndex >= surv.structure.length) {
                    console.log((overHeader || overId))
                }
                surv.structure[overQuestionIndex].questions.push({
                    title: activeQuestion?.title,
                    type: activeQuestion?.type,
                    responses: [],
                    id: activeId
                } as Question);
            }
            posMap = updateMap(posMap, activeId.toString(), surv.structure[overQuestionIndex].questionHeader);
        }

        setFormState({
            unmappedQuestions: unmappedQs,
            posMap: posMap,
            surveyStructure: surv
        })
    };

    const dropAnimation: DropAnimation = {
        ...defaultDropAnimation,
    };

    const confirmData = () => {
        formState.surveyStructure.structure = formState.surveyStructure.structure.filter(y => y.questionHeader != "New question");

        parseData(formState.surveyStructure);
    }

    return (
        <Container>
            <b>Question Mapping</b>
            <p>Drag and drop questions together to align them with how you want your report generated. You can change the heading names by clicking on them.</p>
            <br />
            <div style={{ display: "flex", float: 'right' }}> 
                <Button variant="outlined" disabled={!allowSave} onClick={() => confirmData()}>Confirm </Button>
            </div>
            <br />
            <br />
            <DndContext
                sensors={sensors}
                collisionDetection={closestCorners}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
            >

                {formState.surveyStructure?.structure?.map((structure) => (
                    <Grid container xs={12} key={structure.questionHeader}>
                        <QuestionHeader
                            id={structure.questionHeader}
                            title={structure.questionHeader}
                            questions={structure.questions}
                            setFormState={setFormState}
                            formState={formState}
                        />
                    </Grid>
                ))}
                <Grid item xs={12}>
                    <QuestionHeader
                        id="New question"
                        title="New question"
                        questions={[]}
                        setFormState={setFormState}
                        formState={formState}
                    />
                </Grid>
                <Grid item xs={12}>
                    <QuestionHeader
                        id="Unmapped"
                        title="Unmapped"
                        questions={(formState.unmappedQuestions ?? []).map((q) => ({
                            title: q.title,
                            type: q.type,
                            responses: [],
                            id: q.id
                        }))}
                        setFormState={setFormState}
                        formState={formState}
                    />
                </Grid>
                <DragOverlay dropAnimation={dropAnimation}>
                    {activeQuestion ? <QuestionItem question={{
                        id: activeQuestion.id,
                        title: activeQuestion.title,
                        type: activeQuestion.type,
                        responses: []
                    } as Question} /> : null}
                </DragOverlay>
            </DndContext>
        </Container>
    );
};
