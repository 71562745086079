import { Button, Grid, TextField } from "@mui/material";
import "./css/dynamicInput.css";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

export default function DynamicInput({
  value,
  setValue,
  property,
  label,
}: any) {
  const handleAdd = () => {
    let values = value[property];
    values.push("");
    setValue({ ...value, [property]: values });
  };

  const onChange = (i: number, event: React.ChangeEvent<HTMLInputElement>) => {
    let values = value[property];
    values[i] = event.target.value;
    setValue({ ...value, [property]: values });
  };

  function handleRemove(i: number) {
    const values = [...value[property]];
    values.splice(i, 1);
    setValue({ ...value, [property]: values });
  }

  return (
    <div>
      {value[property].map((input: any, idx: any) => {
        return (
          <Grid container spacing={0} key={`${idx}`}>
            <Grid sm={11} item>
              <TextField
                id="outlined-basic"
                fullWidth
                variant="outlined"
                margin="normal"
                key={idx}
                value={input || ""}
                label={label}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChange(idx, e)
                }
              />
            </Grid>
            <Grid sm={1} item>
              <br/>
              <Button type="button" onClick={() => handleRemove(idx)} 
                className="dynamicRemoveButton"
              >
                <CloseIcon />
              </Button>
            </Grid>
          </Grid>
        );
      })}
      <Button
        onClick={handleAdd}
        disabled={value[property].length == 10}
        sx={{ mt: 1, mr: 1 }}
        startIcon={<AddIcon />}
      >
        Add another
      </Button>
    </div>
  );
}
