import { useAuth0 } from "@auth0/auth0-react";
import { Drawer, Container, Stack, IconButton, styled, LinearProgress } from "@mui/material";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useEffect, useState } from "react";
import { Survey, MappingAnalysisResponse } from "./dataupload/types";
import ReportCreate from "./create";
import FathomClient from "@api/fathomapi";

export default function ReportEdit({ programId, reportId, loadReports, editDrawerOpen, setEditDrawerOpen }: any) {
    const { getAccessTokenSilently } = useAuth0();

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
        marginTop: "20px"
    }));

    const [loading, setLoading] = useState(true);

    const cleanString = (str: string) => {
        return str.replaceAll("â", "").replaceAll(/’/g, "").replaceAll(/'/g, "").replaceAll(/[^\x20-\x7E]/g, '').trim();
    }

    const loadReport = async (id: string) => {
        const apiClient = new FathomClient(await getAccessTokenSilently());
        const { data } = await apiClient.get(`{clientId}/programs/` + programId + "/reports/" + reportId + "/data");
        setParsedSurvey(data);
        setAnalysedSurvey(data)
        setLoading(false);
        setStep(0);
    }

    useEffect(() => {
        const fetchData = async () => {
            loadReport(reportId)
        };
        if (editDrawerOpen) {
            setLoading(true);
            fetchData()
                .catch(console.error);
        }

    }, [editDrawerOpen, reportId]);

    const [step, setStep] = useState(0);
    const [report, setReport] = useState({} as any);
    const [parsedSurvery, setParsedSurvey] = useState({} as Survey);
    const [analysedSurvey, setAnalysedSurvey] = useState({} as MappingAnalysisResponse);
    const [sheetData, setSheetData] = useState([] as any[]);
    const resetCreate = () => {
        setEditDrawerOpen(false);
        setStep(0);
    }
    const parseData = (resultData: Survey) => {
        resultData.title = parsedSurvery.title;
        resultData.reportDate = parsedSurvery.reportDate;
        setParsedSurvey(resultData);
        setStep(1);
    }

    return (
        <Drawer
            anchor='right'
            sx={{
                width: "600px",
            }}
            open={editDrawerOpen}
            PaperProps={{
                sx: { width: "800px" },
            }}
        >
            <DrawerHeader >
                <Container>
                    <Stack spacing={2} direction="row" justifyContent="right" sx={{ mt: '0px' }}>
                        <IconButton aria-label="open">
                            <CloseOutlinedIcon onClick={() => resetCreate()} />
                        </IconButton>
                    </Stack>
                </Container>
            </DrawerHeader>
            {!loading &&
                <ReportCreate
                    reportId={reportId}
                    loadReports={loadReports}
                    setTopReport={setReport}
                    analysing={false}
                    step={step}
                    setStep={setStep}
                    parsedSurvery={parsedSurvery}
                    setParsedSurvey={setParsedSurvey}
                    sheetData={sheetData}
                    analysedSurvey={analysedSurvey}
                    parseData={parseData}
                    setNewReportDrawerOpen={setEditDrawerOpen} />
            }
            {loading &&
                <Container>
                    <LinearProgress color="secondary" />
                    <br />
                </Container>
            }
        </Drawer>

    );
}